import { Form, InputNumber, Tag } from 'antd';
import { NOTIFICATION_CONFIG, WOUND_NOTIFICATION_CONFIG } from 'common/constants';
import { useTranslation } from 'react-i18next';

const NotificationConfig = () => {
    const { t } = useTranslation();

    return (
        <>
            <Form.Item label={t('company.companyForm.averageNotification.title')}>
                <div className="flex flex-wrap items-center xl:justify-between">
                    <Tag color={NOTIFICATION_CONFIG.BELOW_AVERAGE.color} className="m-0">
                        {t(NOTIFICATION_CONFIG.BELOW_AVERAGE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;&#61;</span>

                    <Form.Item
                        className="m-0"
                        name="firstAverageNotification"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.averageNotification.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.averageNotification.placeholder')}
                            min={0}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;</span>

                    <Tag color={NOTIFICATION_CONFIG.AVERAGE.color} className="m-0">
                        {t(NOTIFICATION_CONFIG.AVERAGE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;</span>

                    <Form.Item
                        className="m-0"
                        name="secondAverageNotification"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.averageNotification.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.averageNotification.placeholder')}
                            min={0}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;&#61;</span>
                    <Tag color={NOTIFICATION_CONFIG.ABOVE_AVERAGE.color} className="m-0">
                        {t(NOTIFICATION_CONFIG.ABOVE_AVERAGE.name)}
                    </Tag>
                </div>
            </Form.Item>
            <Form.Item label={t('company.companyForm.woundNotification.title')}>
                <div className="flex flex-wrap items-center xl:justify-between">
                    <Tag color={WOUND_NOTIFICATION_CONFIG.BELOW_AVERAGE.color} className="m-0">
                        {t(WOUND_NOTIFICATION_CONFIG.BELOW_AVERAGE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;&#61;</span>

                    <Form.Item
                        className="m-0"
                        name="firstWoundAverageNotification"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.averageNotification.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.averageNotification.placeholder')}
                            min={0}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;</span>

                    <Tag color={WOUND_NOTIFICATION_CONFIG.AVERAGE.color} className="m-0">
                        {t(WOUND_NOTIFICATION_CONFIG.AVERAGE.name)}
                    </Tag>

                    <span className="px-1 font-bold">&lt;</span>

                    <Form.Item
                        className="m-0"
                        name="secondWoundAverageNotification"
                        rules={[
                            {
                                required: true,
                                message: t('company.companyForm.averageNotification.required')
                            }
                        ]}
                    >
                        <InputNumber
                            placeholder={t('company.companyForm.averageNotification.placeholder')}
                            min={0}
                            style={{ width: 80 }}
                        />
                    </Form.Item>

                    <span className="px-1 font-bold">&lt;&#61;</span>
                    <Tag color={WOUND_NOTIFICATION_CONFIG.ABOVE_AVERAGE.color} className="m-0">
                        {t(WOUND_NOTIFICATION_CONFIG.ABOVE_AVERAGE.name)}
                    </Tag>
                </div>
            </Form.Item>
        </>
    );
};

export default NotificationConfig;
