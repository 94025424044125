import { BLUE, GREEN, RED, YELLOW } from './color';

export const DEFAULT_FIRST_MORTALITY_LEVEL = 5;
export const DEFAULT_SECOND_MORTALITY_LEVEL = 10;

export const DEFAULT_FIRST_MORTALITY_TREND = 1;
export const DEFAULT_SECOND_MORTALITY_TREND = 10;

export const DEFAULT_FIRST_AVERAGE_NOTIFICATION = 5;
export const DEFAULT_SECOND_AVERAGE_NOTIFICATION = 10;

export const COMPANY_CONFIG = {
    MORTALITY_LEVEL: 'MortalityLevel',
    MORTALITY_TREND: 'MortalityTrend',
    AVERAGE_NOTIFICATION: 'AverageNotification',
    WOUND_AVERAGE_NOTIFICATION: 'WoundAverageNotification'
};

export const MORTALITY_CONFIG = {
    ACCEPTABLE: {
        key: 'acceptable',
        name: 'company.companyConfig.acceptable',
        color: GREEN
    },
    UNWANTED: {
        key: 'unwanted',
        name: 'company.companyConfig.unwanted',
        color: YELLOW
    },
    UNACCEPTABLE: {
        key: 'unacceptable',
        name: 'company.companyConfig.unacceptable',
        color: RED
    }
};

export const NOTIFICATION_CONFIG = {
    BELOW_AVERAGE: {
        key: 'belowAverage',
        name: 'company.companyConfig.belowAverage',
        color: GREEN
    },
    AVERAGE: {
        key: 'average',
        name: 'company.companyConfig.average',
        color: BLUE
    },
    ABOVE_AVERAGE: {
        key: 'aboveAverage',
        name: 'company.companyConfig.aboveAverage',
        color: RED
    }
};

export const WOUND_NOTIFICATION_CONFIG = {
    BELOW_AVERAGE: {
        key: 'belowAverage',
        name: 'company.companyConfig.belowAverage',
        color: GREEN
    },
    AVERAGE: {
        key: 'average',
        name: 'company.companyConfig.average',
        color: BLUE
    },
    ABOVE_AVERAGE: {
        key: 'aboveAverage',
        name: 'company.companyConfig.aboveAverage',
        color: RED
    }
};
